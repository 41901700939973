import axios from '@/plugins/axios';

class EmailService {
  getInsights(data) {
    this.data = data;
    return axios().get('admin/email/insights', { params: this.data }).then((response) => response.data);
  }

  getHistory(data) {
    this.data = data;
    return axios().get('admin/email/history', { params: this.data }).then((response) => response.data);
  }
}

export default new EmailService();
